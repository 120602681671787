<template>
  <div class="position">
    <div class="login_table">
      <div class="left_wrapper"><img src="../assets/img/login/login-m.png" alt=""></div>
      <div class="right_wrapper">
        <div class="title">欢迎登录羽林数据</div>
        <!-- <div class="choice_list">
          <div class="wx">微信登录</div>
          <div class="tle">手机登录</div>
          <div class="active_chocie_border"></div>
        </div> -->
        <div class="tel_login_table">
          <div class="telwrapper">
            <div class="box" :style="{borderColor: checkFrom.tel.hidden ? '#f00' : '#d0d9e5'}">
              <div>中国大陆（86）</div>
              <input type="text" id="tel" placeholder="手机号" v-model="form.tel" @blur="telBlur">
            </div>
            <div class="error" v-if="checkFrom.tel.hidden">{{checkFrom.tel.ErrorText}}</div>
          </div>
          <div class="codewrapper">
            <div class="box">
              <input type="text" id="code" :style="{borderColor: checkFrom.code.hidden ? '#f00' : '#d0d9e5'}" placeholder="验证码" @blur="codeBlur" v-model="form.code">
              <div @click="getCode">{{codeText}}</div>
            </div>
            <div class="error" v-if="checkFrom.code.hidden">{{checkFrom.code.ErrorText}}</div>
          </div>
          <div class="login_btn" @click="login">登录</div>
          <div class="user_list">
            <img @click="read" class="nobtn" :src="is_read ? require('../assets/img/login/sure.png') : require('../assets/img/login/no.png')" alt="">
            <a href="#"> 《隐私政策》</a>|<a href="#">《用户服务协议》</a>
          </div>
        </div>
        <!-- <div class="wx_logintable" v-if="false">
          <img src="../assets/img/login/code.png" alt="微信二维码">
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        code: "",
        tel: "",
      },
      checkFrom: {
        tel: {
          ErrorText: "手机号不能为空",
          hidden: false,
        },
        code: {
          ErrorText: "验证码不能为空",
          hidden: false,
        },
      },

      // 是否阅读隐私政策
      is_read: false,
      timer: null,
      codeText: "获取验证码",
      codeNum: 60,
    };
  },
  created() {
    this.codeNum = Number(localStorage.getItem("countDown"));
    this.code();
  },
  mounted() {},
  methods: {
    telBlur(e) {
      if (!this.form.tel) {
        this.checkFrom.tel = {
          ErrorText: "手机号不能为空",
          hidden: true,
        };
      } else {
        this.RegExpTel();
      }
    },
    codeBlur(e) {
      if (!this.form.code) {
        this.checkFrom.code = {
          ErrorText: "验证码不能为空",
          hidden: true,
        };
      } else {
        this.checkFrom.code.hidden = false;
      }
      return this.checkFrom.code.hidden;
    },
    RegExpTel() {
      let reg = new RegExp(
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      );
      if (reg.test(this.form.tel)) {
        this.checkFrom.tel.hidden = false;
      } else {
        this.checkFrom.tel = {
          ErrorText: "请输如正确的手机号",
          hidden: true,
        };
      }

      return this.checkFrom.tel.hidden;
    },
    // 阅读协议
    read() {
      this.is_read = !this.is_read;
    },

    // 获取验证码
    getCode() {
      if (this.RegExpTel()) {
        return;
      }
      this.code();
    },

    code() {
      if (this.timer) {
        return;
      }

      if (this.codeNum > 0) {
        this.timer = setInterval(() => {
          --this.codeNum;

          this.codeText = this.codeNum + "秒后重试";
          localStorage.setItem("countDown", this.codeNum);
          if (this.codeNum === 0) {
            clearInterval(this.timer);
            this.timer = null;
            this.codeText = "重新获取";
            this.codeNum = 60;
          }
        }, 1000);
      } else {
        this.codeText = "重新获取";
        this.codeNum = 60;
      }
    },

    login() {
      if (this.RegExpTel()) {
        return;
      }

      if (this.codeBlur()) {
        return;
      }

      if (!this.is_read) {
        this.read();
      }
    },
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: GraublauWeb;
  src: url("../assets/font/SourceHanSansCN-Light.otf");
}

.position {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: url("../assets/img/login/login-bg.png");
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .login_table {
    display: flex;
    width: 930px;
    height: 488px;
    background: #ffffff;
    box-shadow: 0px 0px 7px 0px rgba(15, 99, 239, 0.2);
    border-radius: 10px;
    padding: 68px 104px 34px 106px;
    .left_wrapper > img {
      width: 422px;
      margin-right: 186px;
    }

    .right_wrapper {
      padding-top: 72px;
      .title {
        font-size: 28px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #1740df;
        margin-bottom: 52px;
        text-align: center;
      }
      .choice_list {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        margin-bottom: 40px;
        & > div {
          flex: 1;
          text-align: center;
          padding-bottom: 12px;
          border-bottom: 1px solid #1740df;
          font-size: 16px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #333333;
          cursor: pointer;
        }
        // .active_chocie_border {
        //   width: 50%;
        //   position: absolute;
        //   right: 0;
        //   bottom: 0;
        //   border-bottom: 1px solid #1740df !important;
        //   -webkit-transition: right 0.4s ease;
        //   -moz-transition: right 0.4s ease;
        //   -o-transition: right 0.4s ease;
        //   transition: right 0.4s ease;
        // }
      }
      .tel_login_table {
        font-size: 14px;
        input {
          box-sizing: border-box;
          padding: 0 10px;
        }
        .box {
          display: flex;
          align-items: center;
          margin-bottom: 38px;
        }
        .telwrapper {
          position: relative;
          .box {
            border: 1px solid #d0d9e5;
            padding-left: 10px;

            div {
              position: relative;
              color: #475669;

              &::after {
                content: " ";
                display: block;
                position: absolute;
                top: 50%;
                right: 0px;
                transform: translateY(-50%);
                width: 1px;
                height: 14px;
                background: #c8d2e0;
              }
            }
            input {
              flex: 1;
              height: 41px;
              background: #fdfdfd;
              border: 0;
              outline: none;
              border-radius: 4px;
            }
          }
        }
        .codewrapper {
          position: relative;
          .box {
            & > input {
              width: 195px;
              height: 41px;
              background: #fdfdfd;
              border: 1px solid #d0d9e5;
              border-radius: 0 !important;
              outline: none;
              border-radius: 4px;
              margin-right: 10px;
            }
            & > div {
              width: 115px;
              height: 41px;
              background: #e5effa;
              border: 1px solid #163ede;
              border-radius: 4px;
              cursor: pointer;
              text-align: center;
              line-height: 41px;
              font-size: 14px;
              font-family: GraublauWeb;
              font-weight: 400;
              color: #1740df;
              &:hover {
                background: #163ede;
                color: #fff;
              }
            }
          }
        }
        .error {
          position: absolute;
          bottom: -17px;
          color: #f00;
        }
        .login_btn {
          width: 320px;
          height: 41px;
          background: linear-gradient(90deg, #0caeeb, #0c78f5, #163ede);
          box-shadow: 0px 0px 7px 0px rgba(15, 99, 239, 0.5);
          border-radius: 4px;
          line-height: 41px;
          text-align: center;
          font-size: 14px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 20px;
          cursor: pointer;
        }
        .user_list {
          font-size: 14px;
          font-family: GraublauWeb;
          font-weight: 400;
          color: #1740df;
          display: flex;
          align-items: center;
          & > a {
            font-size: 14px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #1740df;
          }
          & > img {
            width: 15px;
            height: 15px;
            margin-right: 6px;
            cursor: pointer;
          }
          .surebtn {
            display: none;
          }
        }
      }
      .wx_logintable {
        height: 172px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url("../assets/img/login/biankuang.png") no-repeat;
        background-position: center center;
        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }
}
</style>